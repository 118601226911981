<template>
  <Layout tituloPagina="Bancos">
    <div class="row">
      <div class="col-md-5">
        <div class="text-right mb-4">
          <router-link
            :to="{ name: 'nuevoBanco' }"
            class="btn btn-success btn-sm"
          >
            <i class="mdi mdi-plus-thick"></i>
            Nuevo banco
          </router-link>
        </div>
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">Listado de bancos</h4>
            <div>
              <a href="#" @click="cerrar()" onclick="return false;">
                <i class="mdi mdi-close text-dark"></i>
              </a>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover align-middle table-nowrap" id="tabla-bancos">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th class="text-center" style="width: 180px">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="banco in bancos" :key="banco.id">
                    <td>{{ banco.nombre }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'edicionBanco',
                          params: { id: banco.id }
                        }"
                        class="btn btn-warning btn-sm"
                      >
                        <i class="mdi mdi-content-save-edit-outline"></i>
                        Editar
                      </router-link>
                      <button
                        class="btn btn-danger btn-sm"
                        @click="preguntaEliminar(banco)"
                      >
                        <i class="mdi mdi-trash-can-outline"></i>
                        Eliminar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="text-right">
          <button @click="atras()" class="btn btn-secondary">
            <i class="mdi mdi-chevron-left"></i>
            Atras
          </button>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import BancoSrv from '@/services/BancoSrv.js'
import Swal from 'sweetalert2'
export default {
  name: 'ListadoBancos',
  components: { Layout },
  data() {
    return {
      bancos: []
    }
  },
  created: function() {
    var self = this

    self.refrescarBancos()
  },
  methods: {
    atras: function() {
      this.$router.go(-1)
    },
    cerrar: function() {
      this.atras()
    },
    preguntaEliminar: function(banco) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminación de banco",
        html: `¿Estás seguro de eliminar el banco <strong>${banco.nombre}</strong>?`,
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          BancoSrv.eliminar(banco.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success");
            self.refrescarBancos()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudieron eliminar el banco'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    refrescarBancos: function() {
      var self = this

      iu.spinner.mostrar('#tabla-bancos')

      BancoSrv.bancosJSON().then(response => {
        self.bancos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los bancos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tabla-bancos')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tabla-bancos')
  }
}
</script>